
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons-vue';
import Editor from './component/editor';

export default {
    name: "BasicAddressBook",
    components: {
        DeleteOutlined,
        PlusOutlined,
        Editor
    },
    data() {
        return {
            url: '/admin/ticket_type/list',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 导入地址Loading状态
            import_loading: false
        };
    },
    computed: {
        columns() {
            return [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    key: "type_title",
                    title: this.$t('类型名称'),
                    dataIndex: 'type_title',
                    width: 300,
                    sorter: true
                },
                {
                    key: "ticket_content_format",
                    title: this.$t('工单内容填写格式描述'),
                    dataIndex: 'ticket_content_format',
                    sorter: true,
                    slots: {
                        customRender: "ticket_content_format"
                    }
                    // width: 400
                },
                {
                    key: "charge_type",
                    title: this.$t('计费方式'),
                    dataIndex: 'charge_type',
                    width: 150,
                    sorter: true,
                    customRender: ({
                        text
                    }) => {
                        let str = "";
                        switch (text) {
                            case 1:
                                str = "按票收费"
                                break
                            case 2:
                                str = "按件收费"
                                break
                            case 3:
                                str = "免费"
                                break
                            default:
                                break
                        }

                        return str
                    }
                },
                {
                    key: "charge_value",
                    title: this.$t('费用'),
                    dataIndex: 'charge_value',
                    sorter: true,
                    width: 150,
                    customRender: ({
                        text,
                        record
                    }) => {
                        return `${text} ${record.charge_currency || ''}`
                    }
                },
                {
                    key: 'create_user_contact_name',
                    title: this.$t('创建人'),
                    dataIndex: 'create_user_contact_name',
                    width: 150,
                    sorter: true
                },
                {
                    key: 'update_time',
                    title: this.$t('更新时间'),
                    dataIndex: 'create_time',
                    width: 180,
                    sorter: true
                },
                {
                    key: 'create_time',
                    title: this.$t('创建时间'),
                    dataIndex: 'create_time',
                    width: 180,
                    sorter: true
                },
                {
                    key: 'action',
                    title: this.$t('操作'),
                    width: 120,
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/ticket_type/delete', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/ticket_type/delete', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        // 打开对话详情
        showMessage() {
            this.visibleMessage = true;
        }
    }
}
