
import {
    ref
} from "vue"
import axios from "axios"
import { message } from 'ant-design-vue';
export default {
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 表单数据
            form: Object.assign({
                ticket_content_format: ""
            }, this.data),
            // 表单验证规则
            rules: {
                type_title: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                charge_type: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'number',
                    trigger: 'change'
                }],
                charge_value: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'number',
                    trigger: 'blur'
                }],
                charge_currency: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 是否允许修改用户余额
            allow_edit_balance: false
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
            } else {
                this.form = {};
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    methods: {
        /**
         * 保存数据
         */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post('/admin/ticket_type/update', this.form).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.form = {};
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        onClose() {
            this.updateVisible(false);
        }
    },
    setup() {
        const CURRENCY = ref([]);
        const getCurrencyList = () => {
            axios.get('/admin/currency/list', {
                params: {
                    page: 1,
                    limit: 999,
                    sort: "sort",
                    order: "asc"
                }
            }).then(res => {
                if (res.data.code == 0) {
                    CURRENCY.value = res.data.data;
                }
            }).catch(e =>{
                message.error(e.message)
            })
        }
        getCurrencyList();
        return {
            CURRENCY
        }
    }
}
